import api from "@/base/utils/request"

import fetchFile from "@/base/utils/download";


// 收集详情列表
export const opinionDataIndex= data => {
  return api({
    url: "/admin/opinion/opinion/index",
    method: "post",
    data
  });
};
// 删除
export const deleteRow = data => {
  return api({
    url: "/admin/opinion/opinion/delete",
    method: "post",
    data
  });
};
// 列表导出
export const exportList = (data) => {
  return fetchFile({
    url: "/admin/opinion/opinion/exportIndex",
    method: "post",
    data,
    download: true,
  });
};