<template>
  <div class="list-page">
    <list-layout
      ref="listLayout"
      :filter-form="filterForm"
      :thead="commonThead"
      :on-fetch="getList"
      :on-delete="handleDelete"
      @stats="getListdone"
      @command="handleOperation"
    >
      <template v-slot:filter>
        <list-filter :filterForm="filterForm" :uploadFilter="ok" />
      </template>
      <template slot="item_user_name" slot-scope="{ row }">
        <el-button type="text" @click="goDetail(row)">
          {{ row.user_name }}</el-button
        >
      </template>
      <template slot="item_content_btn" slot-scope="{ row }">
        <div class="flex">
          <el-button type="text" @click="checkContent(row)">查看内容</el-button>
        </div>
      </template>
    </list-layout>
    <el-dialog title="意见内容" class="import-article dialog-vertical" :visible="checkDialog" width="450px" @close="closeCheckDialog">
        {{ currentItem.content }}
  </el-dialog>
  </div>
</template>
<script>
import ListStatusText from '@/base/components/List/ListStatusText'
import ListLayout from '@/base/layout/ListLayout'
import listFilter from '../components/DetailListFilter'

import Reminder from '@/modules/common/components/Reminder.vue'
import ListImage from '@/base/components/List/ListImage'

import { opinionDataIndex, deleteRow } from '../api/detail'
export default {
  components: {
    listFilter,
    ListLayout,
    ListStatusText,
    Reminder,
    ListImage,
  },
  data() {
    return {
      tabCommonArr: [{ label: '全部', name: 'all' }],
      // 公共版数据表格
      commonThead: [
        { type: 'selection' },
        { label: '用户名', prop: 'user_name', type: 'slot' },
        { label: '手机号', prop: 'phone' },

        { label: '意见内容', prop: 'content' },
        { label: '', prop: 'content_btn', type: 'slot' },
        {
          label: '发表时间',
          prop: 'create_time',
          width: 200,
          sortable: true,
        },
        {
          label: '操作',
          type: 'operation',
          width: 100,
          operations: [{ command: 'delete' }],
        },
      ],
      // 筛选对象
      filterForm: {
        start_time: -1, //开始时间
        end_time: -1, //结束时间
        phone: '',
        // 信息收集id
        opinion_data_id: this.$route.params.id,
        page_size: 15, //每页多少条数据
        user_name: '',
      },
      loading: false,
      // 是否显示提示
      showReminder: false,
      // 当前操作项
      currentItem:'',
      checkDialog:false
    }
  },
  methods: {
    handleOperation(e) {
      switch (e.command) {
        case 'check': {
          console.log(e)
          break
        }
        default:
      }
    },
    getList(data) {
      this.loading = true
      return opinionDataIndex(data)
    },
    // 数据删除
    handleDelete(idList, forever = false) {
      console.log(idList)
      deleteRow({ id: idList })
        .then((res) => {
          this.$message.success(res.msg)
          this.refreshList()
        })
        .catch((err) => {
          this.loading = false
        })
    },
    // 获取到的数据
    getListdone(res) {
      console.log(res)
      this.loading = false
    },
    // 筛选回调
    ok(e) {
      this.filterForm = { ...this.filterForm, ...e, page: 1 }
      this.$refs.listLayout.getList(this.filterForm)
    },
    // 刷新列表
    refreshList() {
      this.$refs.listLayout.getList()
    },
    goDetail(row) {
      console.log(row)
      this.$router.push({
        name: 'EditMember',
        params: {
          data: this.$enCode({ id: row.user_id, type: 'formal' }),
        },
      })
    },
    checkContent(row) {
      console.log(row)
      this.currentItem = row
      this.checkDialog = true
    },
    closeCheckDialog() {
      this.checkDialog = false
    }
  },

  computed: {
    tenantVersion() {
      return this.$store.getters.tenant.version
    },
  },
  created() {
    console.log(this.tenantVersion)
  },
}
</script>

<style lang="scss" scoped></style>
