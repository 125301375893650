<template>
  <LayoutFilter
    :onFilter="ok"
    :onReset="resetForm"
    :toggleCollapseEnable="false"
    :onExport="onExport"
  >
    <el-form
      :model="form"
      ref="logFilterForm"
      label-width="70px"
      size="medium"
      class="filter-from"
      @submit.native.prevent
    >
      <!-- 模块&用户名&搜索 -->
      <div class="filter-item">
        <el-form-item label="用户名：" prop="user_name">
          <el-input
            v-model="form.user_name"
            placeholder="请输入用户名"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号：" prop="phone">
          <el-input v-model="form.phone" placeholder="请输入手机号"></el-input>
        </el-form-item>
      </div>
      <div class="filter-item">
        <DatePeriodPicker
          label="创建时间："
          start-prop="start_time"
          :start-time.sync="form.start_time"
          end-prop="end_time"
          :endTime.sync="form.end_time"
        />
      </div>
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from '@/base/layout/LayoutFilter'
import DatePeriodPicker from '@/base/components/Form/DatePeriodPicker'
import { exportList } from '../api/detail'

export default {
  name: 'badge-list-filter',
  model: {
    prop: 'filterForm',
    event: 'updateFilter',
  },
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    filterForm: {
      type: Object,
    },
  },
  data() {
    return {
      rules: {},
      data_type_arr: [
        {
          name: '全部',
          value: -1,
        },
        {
          name: '文章',
          value: 0,
        },
      ]
    }
  },
  methods: {
    onExport() {
      const postData = { ...this.form }
      return exportList(postData)
    },
    //重置表单
    resetForm() {
      this.$refs['logFilterForm'].resetFields()
      this.uploadFilter(this.form)
    },
    //筛选
    ok() {
      this.uploadFilter(this.form)
    },
  },
  created() {},
  computed: {
    form: {
      get() {
        console.log(this.filterForm)
        return this.filterForm
      },
      set(val) {
        this.$emit('updateFilter', val)
      },
    },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
}
</script>
<style lang="scss" scoped>
::v-deep.el-input {
  width: auto;
}
</style>
